<template>
<div>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col class="text-center">
        <b-card>
          <b-nav pills>
          <b-nav-text class="mr-4 ml-3">Available templates:</b-nav-text>
          <b-nav-item to="/impact/policies/download/choose-template/basic" exact exact-active-class="active">Basic</b-nav-item>
          <b-nav-item to="/impact/policies/download/choose-template/grouped" exact exact-active-class="active">Grouped</b-nav-item>
        </b-nav>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <router-view></router-view>
</div>
</template>

<script>

export default {
  name: 'PoliciesDownloadTemplateLaytou',
  components: {
  },
  computed: {
  },
  data () {
    return {
    }
  },
  created: async function () {
  },
  methods: {
    download: function () {
      const start = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
        <meta charset='utf-8'>
        <title>Export HTML To Doc</title>
        <style>
          body {
            font-family: arial;
          }
          h1, h2, h3, h4 {
            color: #00338D;
          }
          h4 {
            margin-top: 18px;
            margin-bottom: 6px;
          }
          table {
            border: 1px solid #00338D;
            border-collapse: collapse;
            font-size: 8px;
          }
          td {
            border: 1px solid #00338D;
            border-collapse: collapse;
            padding: 5px;
            vertical-align: top;
          }
          .mb-2 {
            margin-bottom: 6px;
          }
          .section {
            color: white;
            background-color: #00338D;
          }
        </style>
      </head>
      <body>`
      const end = "</body></html>"
      const html = start + document.getElementById('exportElement').innerHTML + end
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = 'cprd-download.doc'
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    }
  }
}
</script>

<style>
  #exportElement > h1, h2, h3, h4 {
    color: #00338D;
  }
  #exportElement h4 {
    margin-top: 18px;
    margin-bottom: 6px;
  }
  #exportElement .section {
    color: white;
    background-color: #00338D;
  }
</style>
